import { logger } from '@/helpers/logger';
import { useQueryParams } from '@/hooks/useQueryParams/useQueryParams';
import { useEffect } from 'react';
import type { AklamioHelperProps } from './types';

const AID_CONFIG: Record<string, string> = {
  '6d33ad9a60b98bb63546936bbddfd0af': 'solar',
  e9185cf41d57d97b5752f30ed833fc2a: 'power-gas', // private AID
  '7640310a814fe3c0782179df9c7040b2': 'power-gas', // business AID
};

const AklamioHelper = ({}: AklamioHelperProps) => {
  const { params } = useQueryParams();
  const aid = params.aid?.toString()?.trim();

  useEffect(() => {
    if (typeof aid !== 'string') {
      return;
    }

    const date = new Date();
    const futureDate = date.setDate(date.getDate() + 30);

    let oldAidCookieValue = {};
    const oldCookieString = document.cookie
      .split('; ')
      .find((cookie) => cookie.startsWith('aklamio.recommendation'));

    if (oldCookieString) {
      try {
        oldAidCookieValue = JSON.parse(oldCookieString.split('=')[1]);
      } catch (error) {
        logger.error('Invalid cookie format', error);
      }
    }

    const currentAidType = Object.entries(AID_CONFIG).reduce(
      (prev: string | null, [key, value]) => (aid.includes(key) ? value : prev),
      null
    );

    if (!currentAidType) {
      logger.error('no type found for aid', aid);
      return;
    }

    const newAidCookieValue = {
      ...oldAidCookieValue,
      [currentAidType]: aid,
    };

    document.cookie = `aklamio.recommendation=${JSON.stringify(
      newAidCookieValue
    )}; expires=${new Date(
      futureDate
    ).toUTCString()}; domain=.enercity.de; path=/;`;
  }, [aid]);

  return null;
};

export default AklamioHelper;
