import { logger } from '@/helpers/logger';
import { useQueryParams } from '@/hooks/useQueryParams/useQueryParams';
import { useEffect } from 'react';

const UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'gclid'];

const UTMTracker = () => {
  const { params } = useQueryParams();

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const hasUTMParams = UTM_PARAMS.some((param) => params[param]);

    if (!hasUTMParams) return;

    const date = new Date();
    const futureDate = date.setDate(date.getDate() + 30);

    let oldUTMCookieValue = {};
    const cookieString = document.cookie
      .split('; ')
      .find((cookie) => cookie.startsWith('utm_params'));

    if (cookieString) {
      try {
        oldUTMCookieValue = JSON.parse(cookieString.split('=')[1]);
      } catch (error) {
        logger.error('Invalid cookie format', error);
      }
    }

    const newUTMParams = UTM_PARAMS.reduce(
      (acc, param) => {
        const value = params[param];

        if (value) {
          acc[param] = Array.isArray(value) ? value[0] : value;
        }

        return acc;
      },
      {} as Record<string, string>
    );

    const newUTMCookieValue = {
      ...oldUTMCookieValue,
      ...newUTMParams,
    };

    document.cookie = `utm_params=${JSON.stringify(
      newUTMCookieValue
    )}; expires=${new Date(futureDate).toUTCString()}; domain=.enercity.de; path=/;`;
  }, [params]);

  return null;
};

export default UTMTracker;
