import { animated, useSpring } from '@react-spring/web';
import clsx from 'clsx';
import styles from './FormFieldLength.module.scss';
import type { FormFieldLengthProps } from './interfaces';

const FormFieldLength = ({
  value = '',
  maxLength,
  show = true,
  isOnGradient = false,
  className,
}: FormFieldLengthProps) => {
  const isValid = value.length <= maxLength;
  const style = useSpring({ opacity: Number(show), config: { duration: 300 } });

  return (
    <animated.span
      className={clsx(
        styles.default,
        {
          [styles.invalid]: !isValid,
          [styles.onGradient]: isOnGradient,
        },
        className
      )}
      style={style}
    >
      {value.length}/{maxLength}
    </animated.span>
  );
};

export default FormFieldLength;
