export enum FormFieldType {
  CheckBoxBlock = 'CheckBoxBlock',
  Email = 'email',
  Number = 'number',
  Text = 'text',
  Textarea = 'textarea',
  Password = 'password',
  Date = 'date',
  Tel = 'tel',
}
