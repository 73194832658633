import Icon from '@uikit/components/Icon/Icon';
import styles from './ModalCloseIcon.module.scss';
import { ModalCloseIconProps } from './type';

const ModalCloseIcon = ({ onClick }: ModalCloseIconProps) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <span
      className={styles.closeIconCross}
      onClick={handleClick}
      aria-label="Modal schließen"
      role="button"
    >
      <Icon variant={'action/close'} size="iconSize24" />
    </span>
  );
};

export default ModalCloseIcon;
